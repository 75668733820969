<template>
	<navbar
		class="mt-5"
		position="absolute"
		:transparent="transparent"
		:color-on-scroll="colorOnScroll"
	>
		<template class="p-0">
			<img
				src="https://eugeniatalent.com/wp-content/uploads/2020/11/Logo_footer.png"
				style="width: 250px"
			/>
		</template>
	</navbar>
</template>

<script>
	import { DropDown, Navbar, NavLink } from "@/components";
	import { Popover } from "element-ui";
	export default {
		name: "MainNavbar",
		components: {
			DropDown,
			Navbar,
			NavLink,
			[Popover.name]: Popover,
		},
		props: {
			transparent: Boolean,
			colorOnScroll: Number,
		},
	};
</script>

<style>
	.max-width {
		max-width: 100% !important;
	}
</style>
