/* eslint-disable */ 

import axios from "axios";

const instance = axios.create({
    baseURL: 'https://api.eugeniatalent.com/api-eugenia-lps/public',
    headers: {
        "content-type": "application/json",
        'Authorization': 'Basic YXBpX2V1Z2VuaWE6ZTg2YXNkSy83NUozKXBZfnNefn5ed0w='
    },
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
});
 
export default {
    // SIGN IN 
    async buscarDadosPagina(data) {
        try {
            const res = await instance.post('/api/lp/listar', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },
    // APPLY
    async apply(data) {
        try {
            const res = await instance.post('/api/adicionar/lead', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },
    async logsCV(data) {
        try {
            const res = await instance.post('/api/adicionar/lead/log', data);
            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    },

    // async listarLeads(data) {
    //     try {
    //         const res = await instance.post('/dashboard/listar/leads', data);
    //         return res.data;
    //     } catch (err) {
    //         console.error(err);
    //         return null;
    //     }
    // },
    // async converterLead(data) {
    //     try {
    //         const res = await instance.post('/dashboard/converter/leads', data);
    //         return res.data;
    //     } catch (err) {
    //         console.error(err);
    //         return null;
    //     }
    // },
    
}
