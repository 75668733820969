<template>
  <div class="wrapper landing-page">
    <div class="page-header page-header">
      <div class="page-header-image grayBG"></div>
      <div id="form" class="container">
        <div class="row flexCenter np">
          <div class="col-md-12">
            <div class="p-5" id="lottieContainer"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div>
              <h2 class="text-black">Thank you</h2>
              <p class="text-black">Your submission has been received.</p>
              <p class="text-black">
                We will be in touch and contact you soon!
              </p>
            </div>
          </div>
        </div>
        <div class="row flexCenter">
          <div class="col-12 text-center">
            <button
              @click="goHome()"
              type="button"
              class="btn btn-round btn-eugenia"
            >
              Back home
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import lottie from "lottie-web";

  export default {
    data() {
      return {};
    },
    methods: {
      loadAnimation() {
        const container = document.getElementById("lottieContainer");
        const animation = lottie.loadAnimation({
          container: container,
          renderer: "svg",
          loop: true,
          autoplay: true,
          path: "/img/lp/letter.json",
        });
      },
      goHome() {
        location.replace("https://eugeniatalent.com/");
      },
    },
    mounted() {
      this.loadAnimation();
    },
  };
</script>
<style scoped></style>
