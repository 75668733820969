<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <a href="https://eugeniatalent.com/"
          ><img src="/img/lp/footer.png"
        /></a>
      </nav>
      <div class="copyright text-white">
        &copy; {{ year }}, Eugenia Talent Recruitment
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    props: {
      backgroundColor: String,
      type: String,
    },
    data() {
      return {
        year: new Date().getFullYear(),
      };
    },
  };
</script>
<style></style>
