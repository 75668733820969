import { render, staticRenderFns } from "./MainNavbar.vue?vue&type=template&id=3dd73232&scoped=true&"
import script from "./MainNavbar.vue?vue&type=script&lang=js&"
export * from "./MainNavbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dd73232",
  null
  
)

export default component.exports