<template>
  <div id="app">
    <!-- End Google Tag Manager (noscript) -->
    <router-view name="header" />

    <router-view />

    <router-view name="footer" />
  </div>
</template>
<script>
  export default {};
</script>

<style>
  .errorForm {
    color: red !important;
    font-weight: 500 !important;
  }
  #lottieContainer svg {
    width: 35vh !important;
  }
  .loading-overlay {
    position: relative;
  }
  span.hoverTerms:hover {
    color: #34ea86 !important;
  }
  .loading-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Adjust background color and opacity as desired */
    z-index: 999999; /* Ensure the overlay appears on top */
    border-radius: 12px;
  }

  .loading-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* Ensure the animation is on top of the overlay */
  }

  .text-green {
    color: #34ea86;
  }
  .btn-eugenia,
  footer.footer.footer-default {
    background: #34ea86 !important;
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1625px !important;
    }
  }
  @media (max-width: 675px) {
    nav.navbar.navbar-expand-lg.mt-5.navbar-transparent.navbar-absolute {
      margin-top: 0px !important;
      padding-top: 25px !important;
    }
    .row.fullHeight.flexCenter {
      text-align: center !important;
    }
    .row.fullHeight.flexCenter h1 {
      text-align: center !important;
      font-size: 4vh !important;
    }
    .backCenter {
      padding-right: 0px;
      margin-right: 0px;
      width: 100% !important;
      justify-content: center !important;
      padding-bottom: 25px !important;
    }
    .row.titleHeader {
      justify-content: center;
    }
    .card-body {
      padding: 0px !important;
    }
    div#form {
      padding-top: 15% !important;
    }
    div.section {
      padding-left: 5% !important;
      padding-right: 5% !important;
    }
    .pbm-5 {
      padding-bottom: 5% !important;
    }
  }
  .page-header:before {
    background-color: transparent !important;
  }
  .mt-6 {
    margin-top: 4rem !important;
  }
  .border-radius {
    border-radius: 12px !important;
  }
  .LN {
    list-style: none;
    padding: 0 !important;
  }
  ul li {
    line-height: 30px;
  }

  ol li {
    padding-bottom: 30px;
  }
  ol.process {
    list-style: none;
    counter-reset: item;
  }
  .icon.icon-success span {
    font-size: 100px;
    color: #34ea86;
  }
  .np {
    padding: 0px !important;
  }
  .process li {
    counter-increment: item;
    margin-bottom: 5px;
  }
  .process li:before {
    margin-right: 10px;
    content: counter(item);
    background: transparent;
    border-radius: 100%;
    color: #000;
    width: 30px;
    text-align: center;
    display: inline-block;
    border: 2px solid #000;
    height: 30px;
    padding-top: 2px;
  }
  .grayBG {
    background-color: #f8f8f8 !important;
  }

  .info-title {
    font-weight: 600;
  }
  .numbers span {
    color: #fff;
    font-size: 80px;
    font-weight: 600;
  }
  b,
  strong {
    font-weight: bold;
  }
  .label {
    font-size: 0.8rem;
  }
  .glowForm {
    box-shadow: 0px 4px 11px #f1f1f1 !important;
    border-radius: 12px !important;
  }
  .btn-eugenia {
    font-size: 1.3em !important;
    font-weight: bold !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  h1,
  .h1 {
    font-size: 4.5em;
  }
  h4,
  .h4 {
    font-size: 1em;
  }
  #form h3,
  #form .h3 {
    font-weight: bold;
  }
  .form-area {
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
    padding: 40px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-area .form-inner {
    width: 100%;
  }
  .form-control {
    display: block;
    width: 100%;
    height: auto;
    padding: 15px 19px;
    font-size: 1rem;
    line-height: 1.4;
    color: #475f7b;
    background-color: #fff;
    border: 1px solid #dfe3e7;
    border-radius: 0.267rem;
    -webkit-transition: border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .form-control:focus {
    color: #475f7b;
    background-color: #fff;
    border-color: #5a8dee;
    outline: 0;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 10%);
  }
  .intl-tel-input,
  .iti {
    width: 100%;
  }
  form *,
  .form-control::placeholder,
  .form-group .el-input__inner::placeholder,
  .el-date-picker .el-input .el-input__inner::placeholder {
    opacity: 1 !important;
    color: #000 !important;
  }
  form .btn {
    color: #fff !important;
  }
  .iti--separate-dial-code .iti__selected-flag,
  .iti--separate-dial-code .iti__selected-flag:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  form input,
  form #mobile_code {
    box-shadow: 1.03212px 1.03212px 4.12849px rgba(0, 0, 0, 0.12) !important;
    border-radius: 5px !important;
    border: none !important;
  }
  .form-group {
    margin-bottom: 30px;
  }
</style>
